import axios from '@/request/http'
import base from '@/request/base'

const homepage = {
  myAdvertising() {
    return axios.post(`${base.admin_url}/cloud/cloudfb/my_advertising`)
  },
  /**
   * 获取周五出海活动列表
   * @param {Number} type 活动类型
   * @param {Number} tag 媒体平台
   * @returns
   */
  getActivityList(type, tag) {
    return axios.get(`${base.admin_url}/cloud/cloudfb/live_list?type=${type}&tag=${tag}`)
  },
  setMessage(params) {
    return axios.post(`${base.admin_url}/cloud/user/set_message`, params)
  },
  getSourceCustomerList(params) {
    return axios.get(`${base.admin_url}/cloud/user/channel_customer_list`, { params: params })
  },
  // 完成修改密码新手任务
  finishPasswordTask() {
    return axios.post(`${base.admin_url}/cloud/user/set_integral`)
  },
}

export default homepage
