<template>
  <el-card header="渠道推荐客户">
    <search-form-content
        :table-type="'selection'"
        :form-items="items"
        :fields="fields"
        :fetch-data="fetchData"
        :selected-data="selectedData"
        @selection-change="handleSelectedChange"
        :row-key="'id'"
        :default-page-size="10">
      <template #total>
        <el-row class="total-data">
          <el-space :size="20">
            <span class="total-item">
              筛选区间总消耗：<span class="bolder color-warning"> {{ totalData.select_spent }}</span>
            </span>
            <span class="total-item">
              总消耗：<span class="bolder color-warning"> {{ totalData.total_spent }}</span>
            </span>
          </el-space>
        </el-row>
      </template>
      <template #selected>
            <span class="selected-data">
              已选择：<span class="bolder color-warning"> {{ selectedData.count }}</span> 个，消耗：<span class="bolder color-warning"> {{ filters.thousands(selectedData.spent) }}</span>
            </span>
      </template>
    </search-form-content>
  </el-card>
</template>

<script setup>
import SearchFormContent from "@/components/base/SearchFormContent";
import api from "@/request/api/homepage";
import {ref} from "vue";
import filters from '@/base/filters/base'

const items = {
  name: {label: '用户名'},
  company: {label: '认证主体'},
  appTimeRange: {type: 'daterange', label: '认证时间'},
  spentTimeRange: {type: 'daterange', label: '消耗区间'},
  spent: {type: 'hidden', value: 0},
  time: {type: 'hidden', value: 0},
  balance: {type: 'hidden', value: 0},
}
const fields = [
  {id: 'name', label: '用户名'},
  {id: 'company', label: '认证主体'},
  {id: 'is_crm', label: '是否认证'},
  {
    id: 'spent', label: 'FB消耗', sortable: 'custom', format: spent => {
      return filters.thousands(spent)
    }
  },
  {
    id: 'tt_spent', label: 'TT消耗', sortable: 'custom', format: spent => {
      return filters.thousands(spent)
    }
  },
  {
    id: 'balance', label: '钱包余额', sortable: 'custom', format: balance => {
      return filters.thousands(balance)
    }
  },
  {id: 'app_date', label: '认证时间', sortable: 'custom'}
]

const totalData = ref({
  select_spent: 0,
  total_spent: 0
})

const selectedData = ref({
  count: 0, spent: 0, items: []
})

const fetchData = async params => {
  let res = await api.getSourceCustomerList(params)
  totalData.value = {
    select_spent: filters.thousands(res.select_spent ? res.select_spent : 0),
    total_spent: filters.thousands(res.total_spent ? res.total_spent : 0),
  }

  return {
    page: {
      total: res.total ? res.total : 0,
      page: params.page ? params.page : 1,
      pagesize: params.pagesize ? params.pagesize : 10,
    },
    data: res.data && res.data.length > 0 ? res.data : []
  }

}
const handleSelectedChange = event => {
  selectedData.value.count = event.length
  selectedData.value.items = event
  selectedData.value.spent = 0
  event.forEach(item => {
    selectedData.value.spent += (parseFloat(item.spent.toString()))
  })
}
</script>

<style scoped>
.total-data {
  color: var(--el-text-color-regular)
}
.selected-data {
  color: var(--el-text-color-regular);
  font-size: 14px;
  margin-top: 15px;
}
</style>