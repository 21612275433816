<template>
    <el-card body-style="padding:0" v-if="guidance.show">
        <el-alert type="info" class="alert-yellow guidance" :closable="false" @click="goFBGuidance(2, guidance.url)">
            <template #title>
                {{ guidance.title ? guidance.title : '广告投放建议' }}
                <el-button plain round v-if="guidance.help" @click="goFBGuidance(4, guidance.help)" type="warning"
                    size="mini">
                    指导手册
                </el-button>
            </template>
            <template #default>
                <el-row class="guidance-text" v-html="guidance.text"></el-row>
                <img src="@/assets/images/accountList/alert.png" alt="" />
            </template>
        </el-alert>
    </el-card>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import api from '@/request/api/account'
import filters from '@/base/filters/base'

const guidance = ref({
    show: false,
    text: '',
    url: ''
})

const goFBGuidance = (type, url) => {
    filters.gotoPage(url)
    api.common.submitFBGuidance(type, url)
}

onMounted(() => {
    api.common.judgeFBGuidance().then(res => {
        if (res.type === 1) {
            guidance.value.text = res.msg
            guidance.value.url = res.url
            guidance.value.show = true
            guidance.value.title = res.title
            guidance.value.help = res.help
            api.common.submitFBGuidance(1, guidance.value.url)
        }
    })
})
</script>

<style lang="less" scoped>
.alert-yellow {
    background-color: rgba(255, 185, 0, 0.08) !important;
    cursor: pointer;


    :deep(.el-alert__title.is-bold) {
        display: flex;
        align-items: center;

        .el-button {
            margin-left: 5px;
            padding: 5px 15px !important;
            min-height: 20px !important;
            color: #FFB900;
            border-color: #FFB900;
            background-color: rgba(0, 0, 0, 0) !important;
        }

        .el-button:hover {
            background-color: #FFB900 !important;
            color: #FFFFFF;
        }
    }

    :deep(.title-img) {
        align-self: center;
        width: 16px;
        height: 16px;
        margin-left: 4px;

        .title-img:hover {
            fill: #2B82FF;
        }
    }

    :deep(.el-alert__description) {
        .guidance-text {
            line-height: 20px;
            color: #595959;
        }

        .guidance-text a {
            align-self: end !important;
        }

        a {
            position: relative;
            font-weight: bolder;
            font-size: 12px;
            color: #FFB900;
            line-height: 20px;
            text-decoration: none;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 1px;
                background-color: #FFB900;
            }
        }

        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
        }
    }
}

.alert-yellow::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #FFb900;
}

:deep(.el-alert__icon) {
    background-color: #e6f7ff !important;
    color: #2b82ff !important;
}

:deep(.el-alert__title.is-bold) {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
}
</style>