<template>
    <el-card>
        <el-carousel height="108px" :interval="5000" trigger="click" indicator-position="none">
            <el-carousel-item @click="goMeta">
                <div class="meta-container">
                    <img src="@/assets/images/workSpace/metaLeft.png" alt="" class="left">
                    <img src="@/assets/images/workSpace/metaRight.png" alt="" class="right">
                </div>
            </el-carousel-item>
            <el-carousel-item @click="goTemplate">
                <div class="template-poster-container">
                    <span>使用预设广告模板，新手也能轻松投放</span>
                    <div class="button">立即使用>></div>
                </div>
            </el-carousel-item>
            <el-carousel-item @click="goBot">
                <div class="bot-poster-container">
                    <img src="@/assets/images/workSpace/yinobotButton.png" alt="">
                </div>
            </el-carousel-item>
            <el-carousel-item @click="goAi">
                <div class="ai-poster-container">
                    <img src="@/assets/images/workSpace/aiButton.png" alt="">
                    <span class="title">AI素材工具</span>
                    <span>一键生成商品营销图</span>
                    <div class="button">立即体验>></div>
                </div>
            </el-carousel-item>
            <el-carousel-item @click="goRule">
                <div class="rule-poster-container">
                    <img src="@/assets/images/workSpace/ruleButton.png" alt="">
                    <span class="title">广告模板自动规则</span>
                    <span>解放双手自动投放无压力</span>
                    <div class="button">立即体验>></div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </el-card>
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import api from '@/request/api/ad'

const { proxy } = getCurrentInstance()

const goMeta = () => {
    window.open('https://chinaexportsummit2024ecom.splashthat.com/?full_url=https%3A%2F%2Fchinaexportsummit2024ecom.splashthat.com%2F', '_blank')
}

const goTemplate = () => {
    api.template.handleEventTracking({ type: 15 }).finally(() => {
        proxy.$router.push('/ad_template')
    })
}

const goBot = () => {
    api.template.handleEventTracking({ type: 16 }).finally(() => {
        window.open('https://messenger.yinobot.com/login', '_blank')
    })
}

const goAi = () => {
    api.template.handleEventTracking({ type: 18 }).finally(() => {
        proxy.$router.push('/piccopilot')
    })
}

const goRule = () => {
    api.template.handleEventTracking({ type: 17 }).finally(() => {
        proxy.$router.push('/ad_template')
    })
}

</script>

<style lang="less" scoped>
:deep(.el-card__body) {
    padding: 0;
}

:deep(.el-carousel__item) {
    cursor: pointer;
}

.meta-container {
    width: 100%;
    height: 108px;
    background-image: url('~@/assets/images/workSpace/metaBg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    .left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
        height: 80%;
        z-index: 9;
    }

    .right {
        position: absolute;
        top: 0;
        right: 8px;
        height: 100%;
    }
}

.template-poster-container {
    width: 100%;
    height: 108px;
    background-image: url('~@/assets/images/workSpace/adTemplateBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    &>span {
        font-family: YouSheBiaoTiHei;
        font-size: 22px;
        color: #FFFFFF;
    }

    .button {
        width: 24%;
        min-width: 120px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-image: url('~@/assets/images/workSpace/adTemplateButton.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #2B82FF;
        margin-top: 10px;
        cursor: pointer;
    }
}

.bot-poster-container {
    width: 100%;
    height: 108px;
    background-image: url('~@/assets/images/workSpace/yinobotBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    &>img {
        height: 100%;
    }
}

.ai-poster-container {
    width: 100%;
    height: 108px;
    background-image: url('~@/assets/images/workSpace/aiBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    &>img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    .title {
        font-family: PingFang SC-Semibold;
        font-weight: 600;
        font-size: 20px;
        color: #262626;
        letter-spacing: unset;
        position: absolute;
        top: 8px;
        left: 40px;
    }

    &>span {
        font-size: 14px;
        letter-spacing: 6px;
        position: absolute;
        top: 42px;
        left: 40px;
    }

    .button {
        font-weight: 500;
        font-size: 14px;
        color: #262626;
        width: 24%;
        min-width: 120px;
        height: 24px;
        background: #D8FF00;
        border-radius: 4px;
        margin-top: 10px;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 60px;
        left: 40px;
        padding: 2px 0;
    }
}

.rule-poster-container {
    width: 100%;
    height: 108px;
    background-image: url('~@/assets/images/workSpace/ruleBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    &>img {
        position: absolute;
        top: 0;
        right: 40px;
        height: 100%;
    }

    .title {
        font-family: PingFang SC-Semibold;
        font-weight: 600;
        font-size: 20px;
        text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15);
        color: #ffffff;
        letter-spacing: unset;
        position: absolute;
        top: 8px;
        left: 40px;
    }

    &>span {
        font-size: 14px;
        letter-spacing: 6px;
        position: absolute;
        top: 42px;
        left: 40px;
        color: #ffffff;
    }

    .button {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        width: 24%;
        min-width: 120px;
        height: 24px;
        background: #0088FF;
        border-radius: 4px;
        margin-top: 10px;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 60px;
        left: 40px;
        padding: 2px 0;
    }
}

@media screen and (max-width: 1350px) {
    .template-poster-container {
        span {
            font-size: 18px;
        }
    }
}
</style>