<template>
  <el-card class="accountManage">
    <template #header>
      <div class="cardHeader">
        <span class="SubHead">账户管理</span>
      </div>
    </template>
    <div class="cardContent">
      <div class="accountList">
        <div class="item">
          <div>
            <img title="Facebook" src="@/assets/images/workSpace/facebook.svg" alt="" />
            <span @click="goAccountList('facebook')">Facebook</span>
          </div>
          <div>
            <div>{{ advertising.fb.active_num }}</div>
            <div @click="goAccountList('facebook')">活跃账户</div>
          </div>
          <div>
            <div>{{ advertising.fb.blocked_num }}</div>
            <div @click="goAccountList('facebook')">被封账户</div>
          </div>
          <div>
            <div>{{ advertising.fb.review_num }}</div>
            <div @click="goAccountList('facebook')">申请中账户</div>
          </div>
          <div>
            <el-button class="routerBtn" type="text" size="small"><span class="LinkText"
                @click="$router.push('/open_account')">去开户</span></el-button>
          </div>
        </div>
        <div class="item">
          <div>
            <img title="TikTok" src="@/assets/images/workSpace/tiktok.svg" alt="" />
            <span>TikTok</span>
          </div>
          <div>
            <div>{{ advertising.tt.active_num }}</div>
            <div @click="goAccountList('tiktok')">活跃账户</div>
          </div>
          <div>
            <div>{{ advertising.tt.blocked_num }}</div>
            <div @click="goAccountList('tiktok')">被封账户</div>
          </div>
          <div>
            <div>{{ advertising.tt.review_num }}</div>
            <div @click="goAccountList('tiktok')">申请中账户</div>
          </div>
          <div>
            <el-button class="routerBtn" type="text" size="small"><span class="LinkText"
                @click="$router.push('/open_account')">去开户</span></el-button>
          </div>
        </div>
        <div class="item">
          <div>
            <img title="Microsoft" src="@/assets/images/workSpace/microsoft.svg" alt="" />
            <span>Microsoft</span>
          </div>
          <div>
            <div>{{ advertising.bing.active_num }}</div>
            <div @click="goAccountList('bing')">活跃账户</div>
          </div>
          <div>
            <div>{{ advertising.bing.blocked_num }}</div>
            <div @click="goAccountList('bing')">被封账户</div>
          </div>
          <div>
            <div>{{ advertising.bing.review_num }}</div>
            <div @click="goAccountList('bing')">申请中账户</div>
          </div>
          <div>
            <el-button class="routerBtn" type="text" size="small"><span class="LinkText"
                @click="$router.push('/open_account')">去开户</span></el-button>
          </div>
        </div>
        <div>
          <div>
            <img title="Google" src="@/assets/images/workSpace/google.svg" alt="" />
            <span>Google</span>
          </div>
          <div>
            <div>{{ advertising.gg.active_num }}</div>
            <div @click="goAccountList('google')">活跃账户</div>
          </div>
          <div>
            <div>{{ advertising.gg.blocked_num }}</div>
            <div @click="goAccountList('google')">被封账户</div>
          </div>
          <div>
            <div>{{ advertising.gg.review_num }}</div>
            <div @click="goAccountList('google')">申请中账户</div>
          </div>
          <div>
            <el-button class="routerBtn" type="text" size="small"><span class="LinkText"
                @click="$router.push('/open_account')">去开户</span></el-button>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import { getCurrentInstance } from 'vue'

const { proxy } = getCurrentInstance()
const props = defineProps({
  advertising: {
    type: Object,
    default: () => { },
  },
})

// 跳转到对应媒体账户列表
const goAccountList = (name) => {
  proxy.$router.push({ name: 'advertising_list', query: { type: name } })
}
</script>

<style lang="less" scoped>
.accountManage {
  :deep(.el-card__body) {
    min-height: 320px;
    height: 320px;
    padding: 20px;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      height: 28px;
      line-height: 28px;
    }
  }

  .cardContent {
    height: 100%;

    .accountList {
      display: flex;
      flex-direction: column;
      height: 100%;

      .item {
        border-bottom: 1px solid #F0F0F0;
      }

      &>div {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;

        &>div {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          text-align: left;

          &:first-child {
            margin-right: 24px;
            border-right: 1px solid #F0F0F0;
          }

          &:last-child {
            flex: 0;
            width: 42px;
          }

          img {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 22px;
          }

          &>div:nth-child(1) {
            font-size: 24px;
            font-family: D-DIN;
            font-weight: 600;
            color: #262626;
            line-height: 24px;
            margin-bottom: 4px;
          }

          &>div:nth-child(2) {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #595959;
            line-height: 20px;
          }

          &>div:nth-child(2)::after {
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            border-right: 1px solid #8c8c8c;
            border-bottom: 1px solid #8c8c8c;
            transform: rotate(-45deg);
            margin-left: 2px;
          }

          &>div:nth-child(2):hover {
            cursor: pointer;
            color: #2b82ff;
          }

          &>div:nth-child(2):hover::after {
            border-right: 1px solid #2b82ff;
            border-bottom: 1px solid #2b82ff;
          }
        }
      }
    }
  }
}
</style>
