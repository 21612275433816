<template>
  <el-card class="popularArticle">
    <template #header>
      <div class="cardHeader">
        <span class="SubHead">热门文章</span>
        <el-button class="routerBtn" type="text" size="small"><span class="link-button"
            @click="goHelpCenter">帮助中心</span></el-button>
      </div>
    </template>
    <ul class="articleList" v-if="advertising.pop_article.length > 0">
      <template v-for="art in advertising.pop_article" :key="art.index">
        <a :href="`${base.url}/help/${art.id}?type=1`" target="_blank" :underline="false">
          <li class="articleItem Text">
            <span class="title">{{ art.name }}</span>
            <span class="arrow"></span>
          </li>
        </a>
      </template>
    </ul>
  </el-card>
</template>

<script setup>
import { getCurrentInstance } from 'vue'
import base from '@/request/base'

const { proxy } = getCurrentInstance()
const props = defineProps({
  advertising: {
    type: Object,
    default: () => { },
  },
})

// 跳转帮助中心
const goHelpCenter = () => {
  window.open(proxy.$router.resolve({ path: '/hc/helpHome' }).href + '?source=ychelphome', '_blank')
}
</script>

<style lang="less" scoped>
.popularArticle {
  :deep(.el-card__body) {
    box-sizing: border-box;
    height: 340px;
  }

  :deep(.el-card__header) {
    padding: 16px 20px;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .routerBtn {
      padding: 0;
      min-height: auto;
    }

    .link-button {
      color: #2B82FF;
      cursor: pointer;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .articleList {
    a {
      display: block;
      text-decoration: none;
      height: 38px;
      line-height: 38px;
      padding-left: 8px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #F8FBFE;
        border-radius: 4px;
      }

      &:hover .title {
        color: #2B82FF;
      }

      &:hover .arrow {
        display: block;
      }
    }

    .articleItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #262626;
      }

      .arrow {
        display: none;
        width: 10px;
        height: 10px;
        border-right: 1px solid #2b82ff;
        border-bottom: 1px solid #2b82ff;
        transform: rotate(-45deg);
        margin-right: 16px;
      }
    }
  }
}
</style>
