<template>
  <el-card class="latestActivity">
    <template #header>
      <div class="cardHeader">
        <div class="tabs">
          <span :class="{ 'activeTab': activeIndex === 1 }" @click="activeIndex = 1; getActivityList()">最新活动</span>
          <span :class="{ 'activeTab': activeIndex === 2 }" @click="activeIndex = 2; getActivityList()">系列课程</span>
          <span :class="{ 'activeTab': activeIndex === 3 }" @click="activeIndex = 3; getActivityList()">线下活动</span>
          <span :class="{ 'activeTab': activeIndex === 4 }" @click="activeIndex = 4; getActivityList()">行业报告</span>
        </div>
        <span class="LinkText routerBtn" @click="$router.push('/learnCenter')">查看更多</span>
      </div>
    </template>
    <div class="tabs">
      <div :class="{ 'activeMedia': activeMedia === 4 }" @click="activeMedia = 4; getActivityList()">Facebook</div>
      <div :class="{ 'activeMedia': activeMedia === 5 }" @click="activeMedia = 5; getActivityList()">TikTok</div>
      <div :class="{ 'activeMedia': activeMedia === 18 }" @click="activeMedia = 18; getActivityList()">Microsoft</div>
      <div :class="{ 'activeMedia': activeMedia === 6 }" @click="activeMedia = 6; getActivityList()">Google</div>
    </div>
    <el-carousel indicator-position="outside" v-if="activityList.length > 0" :autoplay="false" trigger="click"
      ref="carouselRef">
      <el-carousel-item v-for="(item, index) in Math.ceil(activityList.length / 3)" :key="index">
        <div v-if="activityList[index * 3]" class="activity-item">
          <a :href="activityList[index * 3].url" target="_blank">
            <el-image style="width: 100%" :src="activityList[index * 3].thumb" fit="fill"
              :title="activityList[index * 3].title" />
            <div class="carousel-echo">
              <p class="title" :title="activityList[index * 3].title">{{ activityList[index * 3].title }}</p>
              <div class="content">
                <div v-if="activeIndex !== 4">
                  <span class="current-price">¥ {{ activityList[index * 3].price_selling }}</span>
                  <span class="original-price" v-show="activityList[index * 3].price_market != ''">¥{{ activityList[index
                    * 3].price_market }}</span>
                </div>
                <div v-else>
                  <span class="time">{{ activityList[index * 3].num }}人已下载</span>
                </div>
                <span class="time">{{ activityList[index * 3].time }}</span>
              </div>
            </div>
          </a>
        </div>
        <div v-if="activityList[index * 3 + 1]" class="activity-item">
          <a :href="activityList[index * 3 + 1].url" target="_blank">
            <el-image style="width: 100%" :src="activityList[index * 3 + 1].thumb" fit="fill"
              :title="activityList[index * 3 + 1].title" />
            <div class="carousel-echo">
              <p class="title" :title="activityList[index * 3 + 1].title">{{ activityList[index * 3 + 1].title }}</p>
              <div class="content">
                <div v-if="activeIndex !== 4">
                  <span class="current-price">¥ {{ activityList[index * 3 + 1].price_selling }}</span>
                  <span class="original-price" v-show="activityList[index * 3 + 1].price_market != ''">¥{{
                    activityList[index * 3 + 1].price_market }}</span>
                </div>
                <div v-else>
                  <span class="time">{{ activityList[index * 3 + 1].num }}人已下载</span>
                </div>
                <span class="time">{{ activityList[index * 3 + 1].time }}</span>
              </div>
            </div>
          </a>
        </div>
        <div v-if="activityList[index * 3 + 2]" class="activity-item">
          <a :href="activityList[index * 3 + 2].url" target="_blank">
            <el-image style="width: 100%" :src="activityList[index * 3 + 2].thumb" fit="fill"
              :title="activityList[index * 3 + 2].title" />
            <div class="carousel-echo">
              <p class="title" :title="activityList[index * 3 + 2].title">{{ activityList[index * 3 + 2].title }}</p>
              <div class="content">
                <div v-if="activeIndex !== 4">
                  <span class="current-price">¥ {{ activityList[index * 3 + 2].price_selling }}</span>
                  <span class="original-price" v-show="activityList[index * 3 + 2].price_market != ''">¥{{
                    activityList[index * 3 + 2].price_market }}</span>
                </div>
                <div v-else>
                  <span class="time">{{ activityList[index * 3 + 2].num }}人已下载</span>
                </div>
                <span class="time">{{ activityList[index * 3 + 2].time }}</span>
              </div>
            </div>
          </a>
        </div>
      </el-carousel-item>
    </el-carousel>
  </el-card>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import api from '@/request/api/homepage'

const props = defineProps({
  advertising: {
    type: Object,
    default: () => { },
  },
})

const activeIndex = ref(1)
const activeMedia = ref(4)
const activityList = ref([])
const carouselRef = ref(null)

const getActivityList = async () => {
  nextTick(() => {
    if (carouselRef && carouselRef.value) {
      carouselRef.value.setActiveItem(0)
    }
  })
  const res = await api.getActivityList(activeIndex.value, activeMedia.value)
  activityList.value = res.data
}

onMounted(() => {
  getActivityList()
})
</script>

<style lang="less" scoped>
.latestActivity {
  :deep(.el-card__body) {
    box-sizing: border-box;
    height: 340px;
    padding: 16px 20px 0 20px;
  }

  :deep(.el-card__header) {
    height: 57px;
    padding: 16px 20px 0 20px;
  }

  .cardHeader {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .tabs {
      height: 100%;

      span {
        display: inline-block;
        height: 100%;
        position: relative;
        font-weight: 400;
        font-size: 16px;
        color: #595959;
        cursor: pointer;
        margin-right: 40px;

        &.activeTab {
          font-family: PingFang SC-Medium;
          font-weight: 500;
          color: #262626;
        }

        &.activeTab::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 64px;
          height: 3px;
          background: #2b82ff;
          border-radius: 5px 5px 0 0;
        }
      }
    }

    .routerBtn {
      cursor: pointer;
    }
  }

  .tabs {
    display: flex;
    align-items: center;

    div {
      padding: 5px 16px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      font-weight: 500;
      font-size: 14px;
      font-family: PingFang SC-Medium;
      color: #606266;
      line-height: 22px;
      text-align: center;
      margin-right: 24px;
      cursor: pointer;

      &.activeMedia {
        color: #ffffff;
        background: linear-gradient(90deg, #2B82FF 0%, #72A3F5 100%);
      }
    }
  }

  .el-carousel {
    overflow: hidden;
  }

  .el-carousel__item {
    width: 100%;
    display: flex;
    margin-top: 8px;
  }

  .activity-item {
    width: 32%;
    height: 80%;
    padding: 8px;
    background: #F8FBFE;
    border-radius: 4px 4px 4px 4px;

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0px 4px 8px 0px rgba(43, 130, 255, 0.15);
    }

    a {
      text-decoration: none;
    }

    :deep(.el-image) {
      height: 180px;
      border-radius: 8px;
    }
  }

  .carousel-echo {
    p.title {
      margin: 2px 0 6px 0;
      font-size: 16px;
      color: #262626;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .current-price {
        font-size: 14px;
        font-family: D-DIN;
        font-weight: 600;
        color: #f56c6c;
        line-height: 20px;
        margin-right: 12px;
      }

      .original-price {
        font-size: 12px;
        font-family: D-DIN;
        font-weight: 500;
        color: #8c8c8c;
        line-height: 20px;
        text-decoration: line-through;
      }

      .time {
        font-size: 14px;
        font-family: D-DIN;
        font-weight: 500;
        color: #595959;
        line-height: 20px;
      }
    }
  }

  :deep(.el-carousel__indicators--horizontal) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8px;

    .el-carousel__indicator--horizontal button {
      width: 90px;
      height: 4px;
      background: #e8e8e8;
      border-radius: 5px;
    }

    .el-carousel__indicator--horizontal.is-active button {
      width: 90px;
      height: 4px;
      background: #2b82ff;
      border-radius: 5px;
      opacity: 1;
    }
  }

  :deep(.el-carousel__arrow) {
    background-color: #ffffff;
    width: 48px;
    height: 48px;

    i {
      font-size: 20px;
      color: #8c8c8c;
    }
  }

  :deep(.el-carousel__arrow--left) {
    top: 45%;
    left: -8px;
  }

  :deep(.el-carousel__arrow--right) {
    top: 45%;
    right: -8px;
  }
}
</style>
